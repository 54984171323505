@use "../base/variables" as *;
@use "../base/breakpoints" as *;

// header varianta
.menuHeaderNav {
	display: none;
	@media (lg(up)) {
		display: flex;
	}
}

.menuHeaderLink {
	position: relative;
	display: flex;
	align-items: center;
	padding-top: 20px;
	padding-right: 14px;
	padding-left: 14px;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
	color: $white-color;
	@media (xl(up)) {
		padding-right: 17px;
		padding-left: 17px;
		letter-spacing: 1px;
	}

	&:hover {
		color: $secondary-color;
	}
}

.menuHeaderLinkActive {
	color: $secondary-color;

	&::before {
		content: "";
		position: absolute;
		right: 17px;
		bottom: -1px;
		left: 17px;
		display: block;
		height: 5px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		background-color: $secondary-color;
	}
}

// footer varianta
.menuFooterNav {
	display: flex;
	flex-wrap: wrap;
}

.menuFooterLink {
	padding-right: 28px;
	text-decoration: none;
	text-transform: uppercase;
	color: $white-color;
	@media (md(up)) {
		letter-spacing: 0.5px;
	}
	@media (lg(up)) {
		letter-spacing: 1px;
	}
	@media (xl(up)) {
		padding-right: 34px;
	}

	&:hover {
		color: $secondary-color;
	}
}

.menuFooterLinkActive {
	color: $secondary-color;
}

// responsive varianta
.menuResponsiveNav {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

.menuResponsiveLink {
	display: block;
	padding: 20px;
	border-bottom: 2px solid rgba($white-color, 0.2);
	font-size: 16px;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: $white-color;

	&:hover {
		color: $secondary-color;
	}
}

.menuResponsiveLinkActive {
	border-bottom-color: $secondary-color;
	color: $secondary-color;
}

.menuResponsiveOpen {
	overflow-y: hidden;
}

.menuResponsiveButton {
	position: relative;
	z-index: 1100;
	width: $menu-button-size;
	height: $menu-button-size;
	cursor: pointer;
	user-select: none;
	background-color: $primary-color;
	-webkit-tap-highlight-color: transparent;

	& > div,
	& > div > div {
		&::before,
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: $menu-button-line;
			transition: all 0.5s cubic-bezier(0.1, 0.9, 0, 1.2);
			background: $white-color;
		}
	}

	& > div {
		&::before {
			top: $menu-button-indentation;
			right: 0;
		}

		&::after {
			bottom: $menu-button-indentation;
			left: 0;
		}
	}

	& > div > div {
		position: absolute;
		display: flex;
		align-items: center;
		width: $menu-button-size;
		height: $menu-button-size;
	}
}

.menuResponsiveButtonOpen {
	& > div {
		&::before,
		&::after {
			width: 0;
		}
	}

	& > div > div {
		&::before {
			transform: rotate(135deg);
		}

		&::after {
			transform: rotate(45deg);
		}
	}
}

.menuResponsiveOverlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	display: none;
	background-color: $primary-color;
}

.menuResponsiveOverlayOpen {
	display: block;
}

.menuResponsiveOverlayLogo {
	display: block;
	padding: 20px 66px 20px $grid-gutter;
	border-bottom: 1px solid rgba($white-color, 0.2);

	@media (md(up)) {
		padding: 20px 86px 20px $grid-gutter-md;
	}
}

.menuResponsiveOverlayInner {
	margin: 0;
	padding: 0 $grid-gutter;
	list-style: none;

	@media (md(up)) {
		padding: 0 $grid-gutter-md;
	}
}

// default varianta
.menuDefaultNav {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.menuDefaultLink {
	color: $white-color;

	&:hover {
		color: $secondary-color;
	}
}

.menuDefaultLinkActive {
	color: $secondary-color;
}
