@use "variables" as *;
@use "breakpoints" as *;

.container {
	max-width: $container-width;
	margin: auto;
	padding-right: $grid-gutter;
	padding-left: $grid-gutter;

	@media (md(up)) {
		padding-right: $grid-gutter-md;
		padding-left: $grid-gutter-md;
	}
}
