@use "variables" as *;

// https://github.com/sindresorhus/modern-normalize/blob/main/modern-normalize.css

*,
::before,
::after {
	box-sizing: border-box;
}

html {
	-moz-tab-size: 4;
	tab-size: 4;
	-webkit-text-size-adjust: 100%;
}

body {
	margin: 0;
}

a {
	-webkit-tap-highlight-color: transparent;
}

hr {
	height: 0;
	color: inherit;
}

abbr[title] {
	text-decoration: underline dotted;
}

b,
strong {
	font-weight: $font-weight-medium;
}

code,
kbd,
samp,
pre {
	font-family: monospace;
	font-size: 1em;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

table {
	border-color: inherit;
	text-indent: 0;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: 100%;
	line-height: $line-height;
}

button,
select {
	text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

:-moz-focusring {
	outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
	box-shadow: none;
}

legend {
	padding: 0;
}

progress {
	vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}

::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

summary {
	display: list-item;
}
