@use "../base/variables" as *;
@use "../base/breakpoints" as *;

.header {
	position: relative;
	border-bottom: 1px solid rgba($white-color, 0.2);
}

.wrap {
	display: flex;
}

.logo {
	flex: 0 1 auto;
	margin-right: 20px;
}

.logoLink {
	display: block;
	margin-top: 20px;
	margin-bottom: 20px;
}

.menu {
	display: flex;
	flex: 0 1 auto;
	margin-left: auto;
}

.response {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	margin-top: 20px;
	@media (lg(up)) {
		display: none;
	}
}
