@use "variables" as *;
@use "breakpoints" as *;
@use "mixins" as *;

.grid {
	display: grid;
	grid-template-columns: repeat(#{$grid-columns}, 1fr);
	grid-template-rows: auto;
	gap: $grid-gutter;

	@media (md(up)) {
		gap: $grid-gutter-md;
	}
}

.col {
	position: relative;
	@include generate-grid-size();
}
