@use "../base/variables" as *;

.text {
	font-weight: $font-weight-regular;
	line-height: 1.67;
	letter-spacing: 0.75px;
}

.noMargin {
	margin: 0;
}

.longText {
	font-weight: $font-weight-light;
	line-height: 2;
	margin: 0 0 30px;
	font-size: 16px;
}

.default {
	margin: 0 0 20px;
}

.white {
	color: rgba($white-color, 0.8);
}

.primary {
	color: $primary-light-color;
}

.secondary {
	color: $secondary-color;
}
