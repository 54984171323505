@use "../base/variables" as *;
@use "../base/breakpoints" as *;

.footer {
	padding-top: 60px;
	padding-bottom: 60px;
	border-top: 1px solid rgba($white-color, 0.2);
	@media (md(down)) {
		text-align: center;
	}
}

.footerLink {
	font-size: 15px;
	text-decoration: none;
	text-transform: uppercase;
	color: $white-color;
	@media (md(up)) {
		letter-spacing: 0.5px;
	}
	@media (lg(up)) {
		letter-spacing: 1px;
	}

	&:hover {
		color: $secondary-color;
	}
}
