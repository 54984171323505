@use "variables" as *;

@mixin generate-grid-size($breakpoints: $breakpoint-up, $columns: $grid-columns) {
	&-center {
		justify-self: center;
	}
	&-middle {
		align-self: center;
	}
	&-left {
		justify-self: flex-start;
	}
	&-right {
		justify-self: flex-end;
	}
	@for $column from 1 to ($columns + 1) {
		@at-root #{&}-#{$column} {
			grid-column-start: span #{$column};
		}
	}
	@each $breakpoint, $value in $breakpoints {
		@media (min-width: #{$value}) {
			&-center {
				@include suffix($breakpoint) {
					justify-self: center;
				}
			}
			&-middle {
				@include suffix($breakpoint) {
					align-self: center;
				}
			}
			&-left {
				@include suffix($breakpoint) {
					justify-self: flex-start;
				}
			}
			&-right {
				@include suffix($breakpoint) {
					justify-self: flex-end;
				}
			}
			@for $column from 1 to ($columns + 1) {
				@at-root #{&}-#{$column} {
					@include suffix($breakpoint) {
						grid-column-start: span #{$column};
					}
				}
			}
		}
	}
}

@mixin suffix($suffix, $delimiter: "\\@") {
	&#{$delimiter}#{$suffix} {
		@content;
	}
}
