@use "../base/variables" as *;
@use "../base/breakpoints" as *;

.head {
	margin: 0 0 30px;
	text-transform: uppercase;
}

.headWhite {
	color: $white-color;
}

.headPrimary {
	color: $primary-color;
}

.headSecondary {
	color: $secondary-color;
}

.h1 {
	font-size: 32px;
	font-weight: $font-weight-extra-light;
	line-height: 1.2;
	letter-spacing: 1px;
	@media (lg(up)) {
		font-size: 48px;
	}
}

.h2 {
	font-size: 26px;
	font-weight: $font-weight-extra-light;
	line-height: 1.2;
	letter-spacing: 1px;
	@media (lg(up)) {
		font-size: 42px;
	}
}

.h3 {
	font-size: 20px;
	font-weight: $font-weight-light;
	line-height: 1.2;
	letter-spacing: 1px;
	@media (lg(up)) {
		font-size: 36px;
		font-weight: $font-weight-extra-light;
	}
}

.h4 {
	font-size: 16px;
	font-weight: $font-weight-light;
	line-height: 1.2;
	letter-spacing: 1px;
	@media (lg(up)) {
		font-size: 28px;
		font-weight: $font-weight-extra-light;
	}
}

.h5 {
	font-size: 18px;
	font-weight: $font-weight-medium;
	line-height: 1.3;
	letter-spacing: 1.8px;
}

.h6 {
	font-size: 15px;
	font-weight: $font-weight-regular;
	line-height: 1.3;
	letter-spacing: 1.8px;
}
