@use "variables" as *;
@use "mixins" as *;

@for $i from 1 through 12 {
	.max-width-#{$i}-col {
		max-width: round((calc(($container-width - $grid-gutter) / $grid-columns) * $i) - $grid-gutter);
	}
}

// bg varianty
.bg-primary {
	color: $white-color;
	background-color: $primary-color;
}

.bg-primary-light {
	color: $primary-color;
	background-color: $primary-light-color;
}

.bg-white {
	color: $primary-color;
	background-color: $white-color;
}

// obecne
.margin-x-auto {
	margin-right: auto;
	margin-left: auto;
}

.margin-last-child-0 {
	> :last-child {
		margin-bottom: 0;
	}
}

.shadow-1 {
	box-shadow: 0 15px 30px 0 rgba($primary-color, 0.15);
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

@each $breakpoint, $value in $breakpoint-up {
	@media (min-width: #{$value}) {
		@at-root .text-left {
			@include suffix($breakpoint) {
				text-align: left;
			}
		}
		@at-root .text-right {
			@include suffix($breakpoint) {
				text-align: right;
			}
		}
		@at-root .text-center {
			@include suffix($breakpoint) {
				text-align: center;
			}
		}
	}
}

// generovane odsazeni pro obsahove prvky
@each $index, $value in $spacing-content {
	.margin-top-#{$index} {
		margin-top: $value;
	}
	.margin-bottom-#{$index} {
		margin-bottom: $value;
	}
	.padding-top-#{$index} {
		padding-top: $value;
	}
	.padding-bottom-#{$index} {
		padding-bottom: $value;
	}
}

@each $breakpoint, $value in $breakpoint-up {
	@media (min-width: #{$value}) {
		@each $index, $value in $spacing-content {
			@at-root .margin-top-#{$index} {
				@include suffix($breakpoint) {
					margin-top: $value;
				}
			}
			@at-root .margin-bottom-#{$index} {
				@include suffix($breakpoint) {
					margin-bottom: $value;
				}
			}
			@at-root .padding-top-#{$index} {
				@include suffix($breakpoint) {
					padding-top: $value;
				}
			}
			@at-root .padding-bottom-#{$index} {
				@include suffix($breakpoint) {
					padding-bottom: $value;
				}
			}
		}
	}
}

// generovane odsazeni pro komponentove prvky
@each $index, $value in $spacing-component {
	.padding-#{$index} {
		padding: $value;
	}
}

@each $breakpoint, $value in $breakpoint-up {
	@media (min-width: #{$value}) {
		@each $index, $value in $spacing-component {
			@at-root .padding-#{$index} {
				@include suffix($breakpoint) {
					padding: $value;
				}
			}
		}
	}
}
