@use "../base/variables" as *;

.primary {
	position: relative;
	display: inline-flex;
	padding: 21px 50px;
	overflow: hidden;
	border-radius: 10px;
	font-weight: $font-weight-medium;
	line-height: 1.2;
	letter-spacing: 1.5px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	box-shadow: 0 15px 30px 0 rgba($secondary-color, 0.24);
	color: $white-color;
	background-color: transparent;
	background-image: linear-gradient(110deg, #00ff86 -23%, #52a7a9 97%, #247ca4 132%);

	span {
		position: relative;
		z-index: 10;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
		opacity: 1;
		background-image: linear-gradient(110deg, #00ff86 -23%, #52a7a9 56%, #247ca4 132%);
	}

	&:hover {
		color: $white-color;
		background-color: transparent;

		&::before {
			opacity: 0;
		}
	}
}

.secondary {
	display: inline-flex;
	padding: 0;
	overflow: hidden;
	border: 2px solid $secondary-color;
	border-radius: 10px;
	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1), background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
	font-weight: $font-weight-medium;
	line-height: 1.2;
	letter-spacing: 1.5px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	box-shadow: 0 15px 30px 0 rgba($secondary-color, 0.24);
	color: $primary-color;
	background-color: $primary-light-color;

	span {
		padding: 19px 50px;
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
		background-color: rgba($secondary-color, 0.1);
	}

	&:hover {
		color: $white-color;
		background-color: $secondary-color;

		span {
			background-color: transparent;
		}
	}
}

.text {
	position: relative;
	display: inline-flex;
	padding: 0 0 10px;
	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
	font-weight: $font-weight-medium;
	line-height: 1.2;
	letter-spacing: 1.5px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	background-color: transparent;

	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 2px;
		background-color: $secondary-color;
	}

	&:hover {
		color: $secondary-color;
		background-color: transparent;
	}
}

.textPrimary {
	color: $primary-color;
}

.textSecondary {
	color: $white-color;
}

.default {
	position: relative;
	display: inline-flex;
	padding: 19px 50px;
	border: 2px solid $secondary-color;
	border-radius: 10px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
	font-weight: $font-weight-medium;
	line-height: 1.2;
	letter-spacing: 1.5px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	box-shadow: 0 15px 30px 0 rgba($secondary-color, 0.24);
	color: $white-color;
	background-color: $primary-color;

	&:hover {
		color: $white-color;
		background-color: $secondary-color;
	}
}
