.illustration {
	width: 100%;
	max-width: 550px;
	height: auto;
}

@keyframes circleAnimation {
	0% {
		offset-distance: 0%;
	}

	100% {
		offset-distance: 100%;
	}
}

.circle1 {
	animation: circleAnimation 3s linear infinite;
	offset-path: path("M362.258 342.691H229.03v48.189");
}

.circle2 {
	animation: circleAnimation 3s linear infinite;
	offset-path: path("M229.03 214.803V44.724");
}

.circle3 {
	animation: circleAnimation 3s linear infinite;
	offset-path: path("M229.03 392.544V262.15");
}

.circle4 {
	animation: circleAnimation 3s linear infinite;
	offset-path: path("M408.5 299.843v-51.024");
}

.circle5 {
	animation: circleAnimation 3s linear infinite;
	offset-path: path("M202 219.5v-99H88.614");
}

.circle6 {
	animation: circleAnimation 3s linear infinite;
	offset-path: path("M49 160.945V280");
}

.circle7 {
	animation: circleAnimation 3s linear infinite;
	offset-path: path("M389.228 120.5H256v99");
}
