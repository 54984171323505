@use "variables" as *;

@font-face {
	font-family: "Poppins-fallback";
	size-adjust: 112.5%;
	ascent-override: 110%;
	src: local("Arial");
}

html {
	line-height: $line-height;
}

body {
	font-family: $font-family;
	font-size: $font-size;
	color: $white-color;
	background-color: $primary-color;
}

p {
}

a {
	text-decoration: none;
	color: inherit;

	&:hover {
		color: $secondary-color;
	}
}
