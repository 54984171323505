@use "../base/variables" as *;

.default {
	display: block;
	margin: 0 0 10px;
	font-size: 14px;
	font-style: normal;
	font-weight: $font-weight-regular;
	line-height: 1.8;
	letter-spacing: 7px;
	text-transform: uppercase;
	color: $secondary-color;
}
