// lora colors
$primary-color: #162745;
$primary-light-color: #f5f7f9;
$secondary-color: #60ba97;
$white-color: #fff;

// lora fonts
$font-family: "Poppins", "Poppins-fallback", Arial, sans-serif;
$font-size: 15px;
$line-height: 1.67;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;

// lora grid
$grid-columns: 12;
$grid-gutter: 20px;
$grid-gutter-md: 40px;
$container-width: 1280px;

// lora breakpoints
$breakpoints: (
	"xs-up": 0px,
	"sm-up": 480px,
	"sm-down": 479px,
	"md-up": 750px,
	"md-down": 749px,
	"lg-up": 1000px,
	"lg-down": 999px,
	"xl-up": 1200px,
	"xl-down": 1199px,
	"xxl-up": 1600px,
	"xxl-down": 1599px,
);
$breakpoint-up: (
	"sm": map-get($breakpoints, "sm-up"),
	"md": map-get($breakpoints, "md-up"),
	"lg": map-get($breakpoints, "lg-up"),
	"xl": map-get($breakpoints, "xl-up"),
	"xxl": map-get($breakpoints, "xxl-up"),
);

// lora responsive menu button
$menu-button-size: 26px;
$menu-button-line: 2px;
$menu-button-indentation: 5px;

// lora spacing
$spacing-content: (
	"0": 0,
	"20": 20px,
	"60": 60px,
	"150": 150px,
);

$spacing-component: (
	"0": 0,
	"30": 30px,
	"40": 40px,
	"50": 50px,
	"80": 80px,
);
